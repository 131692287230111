<template>
  <div>
    <van-nav-bar
      :title="bankcardTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div id="content">
      <ul class="selet">
        <li  @click="liClick()" v-if="bankFormatAccount">
           <span class="text" >{{bankFormatAccount}}</span>
           <span class="text"><van-icon name="arrow" /></span>
        </li>
        <li v-else>
          <span class="text" >No Data</span>
        </li>
      </ul>
  </div>
  </div>
</template>
<script>
export default {
  name: "bankCount",
  data() {
    return {
      bankcardTitle:'',
      message:'Lorem ipsum dolor sit amet ',
      bankFormatAccount:''
    };
  },
  created(){
    let bankData = JSON.parse(window.localStorage.getItem('bankData')) ? JSON.parse(window.localStorage.getItem('bankData')) : {}
    this.bankFormatAccount = bankData.bankFormatAccount
  },
  mounted() {
    this.bankcardTitle = this.$t("mine.MyBankCard")
  },
  methods: {
    liClick(id){
      this.$router.push('/editBank')
    },
    back() {
      this.$router.push('/mine');
    },
  },
};
</script>
<style scoped>
.van-nav-bar{
  background: #fafafa;
}
#content {
  padding: 0;
    height: calc(100vh - 60px);
    box-sizing: border-box;
}
.van-nav-bar__content{
    height: 60px;
}
.van-nav-bar__title{
      font-weight: bold;
}
.selet li{
    background: #fff;
    display: flex;
    padding: 20px 20px;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    /* line-height: 56px; */
}
.selet .text{
color: #333333;
font-size: 14px;
}
.selet .arrow{
font-size: 14px;
color: #999999;
}
</style>